import { FC, PropsWithChildren, createContext, useContext } from 'react';

export const FEATURE_ALLOW_ADD_RECEIPT: string = 'allow-add-reciept';

const flagsContext = createContext<Record<string, unknown>>({});

export const FlagsProvider: FC<
  PropsWithChildren<{ flags: Record<string, unknown> }>
> = ({ flags, children }) => {
  return (
    <flagsContext.Provider value={flags}>{children}</flagsContext.Provider>
  );
};

export function useFlags() {
  const ctx = useContext(flagsContext);

  if (!ctx) {
    throw new Error(
      'Flags is not available, make sure you are using "FlagsProvider".'
    );
  }

  return new Map(Object.entries(ctx));
}
